
import { LOCALES } from '../../../i18n/locales';

export const getRCLandingHome4 = (local) => {
    const resData = local === LOCALES.UKRAINIAN ? resData_ukr : resData_eng;
    return resData;
};

const resData_eng = [
    
    // {
    //     id: 0,
    //     header: 'Hello, dear Rotarians!',
    //     arrcontent: [
    //         {
    //             img: 'https://api.rotary.org.ua/uploads/clubsitecreator/showcase/Icons/syngapore.jpg',
    //             content: [
    //                 {LandingMessage: `We invite you to view the Showcase of District 2232 projects! It was created especially for the Congress in Singapore!`},
    //                 // {LandingButtonLink: "Browse Projects", link: '/projshowcase/browse'},
    //                 // {LandingButtonLink: "Statistics Projects", link: '/projshowcase/stat'},
    //             ],
    //         },

    //     ]
        
    // },
    {
        id: 0,
        header: 'Menu:',
        arrcontent: [
            {
                cards: [
                    {
                        img: 'https://api.rotary.org.ua/uploads/clubsitecreator/showcase/Icons/browse projects.png',
                        buttonUrl: '/rcprojects/christmas24/browse',
                    },
                    {
                        img: 'https://api.rotary.org.ua/uploads/clubsitecreator/showcase/Icons/Statistics Projects.png',
                        buttonUrl: '/rcprojects/christmas24/stat',
                    },
                ]
            },

        ]
        
    },
];


const resData_ukr = [
    {
        id: 0,
        header: 'Привіт, дорогі ротарійці!',
        content: [
            {LandingMessage: `Запрошуємо до перегляду Вітрини проектів Дистрикту 2232`},
        ],
    },
];