import { LOCALES } from '../../../i18n/locales';

export const getClubPageStructure4 = (local) => {

    const translations_ukr = {
        "Вітрина Проектів Д2232": "Вітрина Проектів Д2232",
        "Контактна особа": "Контактна особа",

        "Головна": "Головна",
        "Огляд проектів": "Огляд проектів",
        "Статистика проектів": "Статистика проектів",
    };

    const translations_eng = {
        "Вітрина Проектів Д2232": "Christmas D2232 Projects Showcase",
        "Контактна особа": "Contact Person",

        "Головна": "Home",
        "Огляд проектів": "Browse Projects",
        "Статистика проектів": "Statistics",
    };
    

    const translations = local === LOCALES.UKRAINIAN ? translations_ukr : translations_eng;

    return {
        navbar: {
            title: translations["Вітрина Проектів Д2232"],
            logoUrl: "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/Logo/LogoD2232_withborders.png",
            languages: [
                "ua-UA",
                "en-US",
            ],
            socialsocialNetworks: {
                fb: 'https://www.facebook.com/RotaryInUkraine',
                // youtube: 'https://www.instagram.com/rotary_club_cherkasy?igsh=MWlhNzRjeW0wa2RuMg%3D%3D&utm_source=qr',
                // linkedIn: 'https://youtube.com/@RotaryclubCherkasy?si=PJCBZbOuDVqDxzpU',
            },
            menu: [
                {
                    title: translations["Головна"],
                    lref: '/rcprojects/christmas24',
                },
                {
                    title: translations["Огляд проектів"],
                    lref: '/rcprojects/christmas24/browse',
                },
                {
                    title: translations["Статистика проектів"],
                    lref: '/rcprojects/christmas24/stat',
                },
            ],
        },
        footer: {
            contactPerson: {
                name: translations["Контактна особа:"],
                email: "oncology@2upost.com",
                email2: "paliychuk.oncology@gmail.com",
            },
        }
    }
}
