import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';

import { AuthProvider } from './modules/Auth/frontend/contexts/AuthContext';

import ExternalRedirect from './components2/ExternalRedirect';

import HomePage from './pages/HomePage';
import AboutRiPage from './pages/AboutRiPage';
import FoundationRIPage from './pages/FoundationRIPage';
import AboutD2232 from './pages/AboutD2232';
import ContactsPage from './pages/ContactsPage';
import LeadersD2232Page from './pages/LeadersD2232Page';
import LeadersRIPage from './pages/LeadersRIPage';
import D2232ProjectsPage from './pages/D2232ProjectsPage';
import CommitteesPage from './pages/myrotary/CommitteesPage';
import CoordinationCommitteePage from './pages/myrotary/CoordinationCommitteePage';
import NewsPage from './pages/NewsPage';
import EventsPage from './pages/EventsPage';
import DanatePage from './pages/DanatePage';
import MagazinesPage from './pages/MagazinesPage';
import DocumentsRiPage from './pages/myrotary/DocumentsRiPage';
import DistrictClubsPage from './pages/DistrictClubsPage';
import RotaractClubsPage from './pages/RotaractClubsPage';
import RotaryClubsPage from './pages/RotaryClubsPage';
import AllClubsPage from './pages/AllClubsPage';
import InteractClubsPage from './pages/InteractClubsPage';
import SateliteClubsPage from './pages/SateliteClubsPage';
import MyRotaryClubsPage from './pages/myrotary/MyRotaryClubsPage';
import IccPage from './pages/IccPage';
import InternationalPage from './pages/myrotary/InternationalPage';
import BrandCenterPage from './pages/BrandCenterPage';
import MyEventsPage from './pages/myrotary/MyEventsPage';

import MyHomePage from './pages/myrotary/MyHomePage';
import MyIccPage from './pages/myrotary/MyIccPage';
import ProfileRotarian from './pages/EditorPage/ProfileRotarian';
import ClubProfilePage from './pages/myrotary/ClubProfilePage';
import MemberProfilePage from './pages/myrotary/MemberProfilePage';
import DocumentsPageEdit from './pages/EditorPage/DocumentsPageEdit';
import DocumentsPageEditor from './pages/EditorPage/DocumentsPageEditor';
import ProfileD2232 from './pages/EditorPage/ProfileD2232';
import MyProfileD2232 from './pages/myrotary/MyProfileD2232';
import ClubPage from './pages/ClubPage';
import GrafixPage from './temp/GrafixPage';
import ConferencesPage from './pages/myrotary/ConferencesPage';
import SeminarsPage from './pages/myrotary/SeminarsPage';
import MyBrandCenterPage from './pages/myrotary/MyBrandCenterPage';

import DocumentsPage from './pages/myrotary/DocumentsPage';
import LettersCommitteeChairsPage from './pages/myrotary/LettersCommitteeChairsPage';
import FinancePage from './pages/myrotary/FinancePage';
import BusinessCouncilPage from './pages/BusinessCouncil/BusinessCouncilPage';

import Event1Page from './pages/LandingPages/Event1Page';
import BusinessCouncilLanding from './pages/LandingPages/BusinessCouncilLanding';
import FellowshipsPage from './pages/LandingPages/FellowshipsPage';
import ItCommitteePage from './pages/RC_Cherkasy/ItCommittee/ItCommitteePage';
import InteractCommitteePage from './pages/RC_Cherkasy/InteractCommittee/InteractCommitteePage';
import EventPets24Page from './pages/RC_Cherkasy/EventPets24/EventPets24Page';
import PersonalFolder24Page from './pages/RC_Cherkasy/EventPets24/PersonalFolder24Page';
import RotariadaPage from './pages/RC_Cherkasy/EventRotariada24/RotariadaPage';
import TykoSitesPage from './pages/RC_Cherkasy/EventRotariada24/TykoSitesPage';

import AdminsPage from './pages/MyAdministrations/AdminsPage';
import AdminMaterialsSeminars from './pages/MyAdministrations/AdminMaterialsSeminars';
import AdminPresentations from './pages/MyAdministrations/AdminPresentations';
import AdminCommunique from './pages/MyAdministrations/AdminCommunique';
import AdminCommunique_old from './pages/MyAdministrations/AdminCommunique_old';
import AdminLandings from './pages/MyAdministrations/AdminLandings';
import AdminRProjects from './pages/MyAdministrations/AdminRProjects';
import UserCRUD from './pages/RC_Cherkasy/rotariansBD/adminsPages/UserCRUD';
import CRUDEvents from './pages/RC_Cherkasy/rotariansBD/adminsPages/CRUDEvents';
import CRUDEvents2 from './pages/RC_Cherkasy/rotariansBD/adminsPages/CRUDEvents2';
import RotaryClubsCRUD from './pages/RC_Cherkasy/rotariansBD/adminsPages/RotaryClubsCRUD';
import ProjectsD2232AdminPage from './pages/RC_Cherkasy/rotariansBD/adminsPages/ProjectsD2232AdminPage';
import ChangePasswordFrame from './pages/LoginAndSuccess/ChangePasswordFrame';
import RcCherkasyPage_Admin from './pages/RC_Cherkasy/AdminPages/RcCherkasyPage_Admin';
import AdminSitesEditor from './pages/MyAdministrations/AdminSitesEditor';
import AdminSites from './pages/MyAdministrations/AdminSites';
import AdminSiteMenus from './pages/MyAdministrations/AdminSiteMenus';
import AdminSitePages from './pages/MyAdministrations/AdminSitePages';
import AdminSynchronizeRiDb from './pages/MyAdministrations/AdminSynchronizeRiDb';

import PhotoUploaderPage from './pages/ToolsPages/PhotoUploaderPage';
import PhotosDisplay from './pages/ToolsPages/PhotosDisplay';
import ServerFolders from './pages/ToolsPages/ServerFolders';
import LogsMyPage from './pages/ToolsPages/TestRotaryClubsFromRiBd';
import TranslateComponentText from './pages/ToolsPages/TranslateComponentText';
import TestRotaryClubsFromRiBd from './pages/ToolsPages/TestRotaryClubsFromRiBd';
import SimpleGoogleForm from './pages/ToolsPages/SimpleGoogleForm';
import AiText2Audio from './modules/AI_Audio/AiText2Audio';
import AudioMerger from './modules/AI_Audio/AudioMege_ffmpeg';

import D2232UkrainePage from './pages/RC_Cherkasy/D_2232/pages/D2232UkrainePage';
import RcCherkasyPage from './pages/RC_Cherkasy/RcCherkasy/RcCherkasyPage';
import MyRcCherkasyPage from './pages/RC_Cherkasy/RcCherkasy/MyRcCherkasyPage';
import RcEClubOfUkrainePage from './pages/RC_Cherkasy/RC_EClub/pages/RcEClubOfUkrainePage';
import RcNewLevelPage from './pages/RC_Cherkasy/RC_NewLevel/pages/RcNewLevelPage';
import Rd2232Page from './pages/RC_Cherkasy/RD_2232/pages/Rd2232Page';
import RcLvivRatushaPage from './pages/RC_Cherkasy/RC_LvivRatusha/pages/RcLvivRatushaPage';
import RcmtEnvironmental from './pages/RC_Cherkasy/RCMT_Environmental/pages/RcmtEnvironmental';
import RLearningPage from './pages/RC_Cherkasy/RLearning/RLearningPage';
import RProjectsShowcase from './pages/RC_Cherkasy/R_ProjectsShowcase/RProjectsShowcase';
import RProjectsShowcase2 from './pages/RC_Cherkasy/R_ProjectsShowcase/RProjectsShowcase2';
import RProjectsShowcase3 from './pages/RC_Cherkasy/R_ProjectsShowcase/RProjectsShowcase3';
import RProjectsShowcase4 from './pages/RC_Cherkasy/R_ProjectsShowcase/RProjectsShowcase4';
import RotariansCooperatorPage from './pages/RC_Cherkasy/RotariansCooperator/RotariansCooperatorPage';
import RotarianContactsPage from './pages/RC_Cherkasy/RotariansCooperator/RotarianContactsPage';
import FsIyfrPage from './pages/RC_Cherkasy/FS_IyfrPage/pages/FsIyfrPage';

import MyHomePage_TempShare from './pages/myrotary/MyHomePage_TempShare';
import DocumentsPage_TempShare from './pages/myrotary/DocumentsPage_TempShare';
import FinancePage_TempShare from './pages/myrotary/FinancePage_TempShare';
import EventsPage_TempShare from './pages/EventsPage_TempShare';
import PolicyPage from './pages/PolicyPage';
import TermsofservicePage from './pages/TermsofservicePage';

import { LanguageContextProvider } from './tools/LanguageContextProvider';
import RCalendar from './modules/GoogleCalendar/RCalendar';
import GoogleCalendar from './modules/GoogleCalendar/GoogleCalendar_login';
import QrReadderPage from './modules/QrReadder/QrReadderPage';
import QrGeneratorPage from './modules/QrReadder/QrGeneratorPage';
import RylaCard from './modules/QrReadder/RylaCard';
import CreateGoogleForm from './modules/Google/GoogleForms/CreateGoogleForm';
import FileExplorer from './modules/FS_CMS_ukraine_org/FileExplorer/FileExplorer';
import ClubsMapMapGL from './modules/MapsTools/ClubsMapMapGL';
import ClubsTable from './temp/DataImport/ClubsTable';
import SpeechmaticAudio from './tools/SpeechmaticAudio/Page2';

import RegisterForm from './modules/Auth/frontend/components/RegisterForm';
import LoginForm from './modules/Auth/frontend/components/LoginForm';
import RegisterPage from './modules/Auth/frontend/pages/RegisterPage';
import LoginPage from './modules/Auth/frontend/pages/LoginPage';
import VerifyEmailPage from './modules/Auth/frontend/pages/VerifyEmailPage';
import HomePublicPage from './modules/Auth/frontend/pages/HomePublicPage';
import HomePrivatePage from './modules/Auth/frontend/pages/HomePrivatePage';
import ResetPasswordPage from './modules/Auth/frontend/pages/ResetPasswordPage';
import MyRotaryUa from './modules/Auth/frontend/pages/MyRotaryUa';

function App() {

    return (
        <AuthProvider>
        <LanguageContextProvider>
            <Router>
                <Routes>
                //rotaryd2232ukraine.org
                    <Route path="/home" element={<HomePage />} />

                    <Route path="/about_ri" element={<AboutRiPage />} />
                    <Route path="/leaders_ri" element={<LeadersRIPage />} />
                    <Route path="/foundation_ri" element={<FoundationRIPage />} />
                    {/* <Route path="/aboutrizones" element={<AboutRiPage />} /> */}
                    
                    <Route path="/about_d2232" element={<AboutD2232 />} />
                    <Route path="/leaders_d2232" element={<LeadersD2232Page />} />
                    <Route path="/projects" element={<D2232ProjectsPage />} />
                    <Route path="/myicc" element={<IccPage />} />
                    <Route path="/international" element={<InternationalPage />} />
                    <Route path="/clubs" element={<DistrictClubsPage />} />
                    <Route path="/rotaryclubs" element={<RotaryClubsPage />} />
                    <Route path="/allclubs" element={<AllClubsPage />} />
                    <Route path="/rotaractclubs" element={<RotaractClubsPage />} />
                    <Route path="/sateliteclubs" element={<SateliteClubsPage />} />
                    <Route path="/interactclubs" element={<InteractClubsPage />} />
                    <Route path="/magazines" element={<MagazinesPage />} />
                    <Route path="/events" element={<RCalendar />} />
                    <Route path="/committees" element={<CommitteesPage />} />
                    <Route path="/coordcom" element={<CoordinationCommitteePage />} />
                    <Route path="/donate" element={<DanatePage />} />
                    <Route path="/brandcenter" element={<BrandCenterPage />} />

                    <Route path="/about" element={<ContactsPage />} />

            //my.rotary.org
                    <Route path="/myhome" element={<MyHomePage />} />
                    <Route path="/myprofile" element={<ProfileRotarian />} />
                    <Route path="/myclubprofile" element={<ClubProfilePage />} />
                    <Route path="/mymemberprofile" element={<MemberProfilePage />} />
                    <Route path="/documents_edit" element={<DocumentsPageEdit />} />
                    <Route path="/documents_editor" element={<DocumentsPageEditor />} />
                    <Route path="/myd2232" element={<ProfileD2232 />} />
                    <Route path="/myclubs" element={<MyRotaryClubsPage />} />
                    <Route path="/news" element={<NewsPage />} />
                    <Route path="/myevents" element={<MyEventsPage />} />
                    <Route path="/conferences" element={<ConferencesPage />} />
                    <Route path="/seminars" element={<SeminarsPage />} />
                    <Route path="/icc" element={<IccPage />} />
                    <Route path="/myd2232_" element={<MyProfileD2232 />} />
                    <Route path="/mybrandcenter" element={<MyBrandCenterPage />} />

            //documents
                    <Route path="/documentsRI" element={<DocumentsRiPage />} />
                    <Route path="/communique" element={<DocumentsPage />} />
                    <Route path="/letters" element={<LettersCommitteeChairsPage />} />
                    <Route path="/finance" element={<FinancePage />} />
            //landings
                    <Route path="/event_BusinessCouncil" element={<BusinessCouncilLanding />} />
                    <Route path="/event1" element={<Event1Page />} />
                    <Route path="/fellowships" element={<FellowshipsPage />} />
                    <Route path="/pets24" element={<EventPets24Page />} />
                    <Route path="/pets24/:id" element={<EventPets24Page />} />
                    <Route path="/myfolder24" element={<PersonalFolder24Page />} />
                    <Route path="/myfolder24/:id" element={<PersonalFolder24Page />} />
                    <Route path="/rotariada" element={<RotariadaPage />} />
                    <Route path="/rotariada/:id" element={<RotariadaPage />} />
                    <Route path="/ds/:sitepath" element={<TykoSitesPage />} />
                    <Route path="/ds/:sitepath/:id" element={<TykoSitesPage />} />

            //administrations
                    <Route path="/admins" element={<AdminsPage />} />
                    <Route path="/admin_seminars" element={<AdminMaterialsSeminars />} />
                    <Route path="/admin_presentations" element={<AdminPresentations />} />
                    <Route path="/admin_landings" element={<AdminLandings />} />
                    <Route path="/admin_rprojects" element={<AdminRProjects />} />
                    <Route path="/admin_rotary_clubs" element={<RotaryClubsCRUD />} />
                    <Route path="/admin_sites_editor" element={<AdminSitesEditor />} />
                    <Route path="/admin_sites" element={<AdminSites />} />
                    <Route path="/admin_sitemenus" element={<AdminSiteMenus />} />
                    <Route path="/admin_sitepages" element={<AdminSitePages />} />
                    <Route path="/changepwd" element={<ChangePasswordFrame />} />
                    <Route path="/rc_cherkasy_admin/:id" element={<RcCherkasyPage_Admin />} />
                    <Route path="/synchronize_ridb" element={<AdminSynchronizeRiDb />} />

            //tools
                    <Route path="/uploadfiles" element={<PhotoUploaderPage />} />
                    <Route path="/photos" element={<PhotosDisplay />} />
                    <Route path="/photos/:path/*" element={<PhotosDisplay />} />
                    <Route path="/folders" element={<ServerFolders />} />
                    <Route path="/folders/:path/*" element={<ServerFolders />} />
                    <Route path="/logs*" element={<LogsMyPage />} />
                    <Route path="/aitest" element={<TranslateComponentText />} />
                    <Route path="/test_RiDb" element={<TestRotaryClubsFromRiBd />} />
                    <Route path="/test_tykoForms" element={<SimpleGoogleForm />} />
                    <Route path="/test_text2audio" element={<AiText2Audio />} />
                    <Route path="/test_text2audio_google" element={<AiText2Audio isGoogle="true"/>} />
                    <Route path="/audio_merge" element={<AudioMerger />} />
                    //for clubs

            //temp
                    <Route path="/d2232" element={<D2232UkrainePage />} />
                    <Route path="/d2232/:id" element={<D2232UkrainePage />} />
                    <Route path="/grafix360" element={<GrafixPage />} />
                    <Route path="/rc_cherkasy" element={<RcCherkasyPage />} />
                    <Route path="/rc_cherkasy/:id" element={<RcCherkasyPage />} />
                    <Route path="/my_rc_cherkasy" element={<MyRcCherkasyPage />} />
                    <Route path="/my_rc_cherkasy/:id" element={<MyRcCherkasyPage />} />
                    <Route path="/rc_eclubofukraine" element={<RcEClubOfUkrainePage />} />
                    <Route path="/rc_eclubofukraine/:id" element={<RcEClubOfUkrainePage />} />
                    <Route path="/rc_newlevel" element={<RcNewLevelPage />} />
                    <Route path="/rc_newlevel/:id" element={<RcNewLevelPage />} />
                    <Route path="/rd_2232" element={<Rd2232Page />} />
                    <Route path="/rd_2232/:id" element={<Rd2232Page />} />
                    <Route path="/rc_lvivratusha" element={<RcLvivRatushaPage />} />
                    <Route path="/rc_lvivratusha/:id" element={<RcLvivRatushaPage />} />
                    <Route path="/rcmt_environmental" element={<RcmtEnvironmental />} />
                    <Route path="/rcmt_environmental/:id" element={<RcLvivRatushaPage />} />
                    <Route path="/r_learning" element={<RLearningPage />} />
                    <Route path="/project_showcase" element={<RProjectsShowcase />} />
                    <Route path="/project_showcase/:id" element={<RProjectsShowcase />} />
                    <Route path="/projshowcase" element={<RProjectsShowcase2 />} />
                    <Route path="/projshowcase/:id" element={<RProjectsShowcase2 />} />
                    <Route path="/educationfellowship" element={<RProjectsShowcase3 />} />
                    <Route path="/educationfellowship/:id" element={<RProjectsShowcase3 />} />
                    <Route path="/itcommittee" element={<ItCommitteePage />} />
                    <Route path="/itcommittee/:id" element={<ItCommitteePage />} />
                    <Route path="/interactcommittee2" element={<InteractCommitteePage />} />
                    <Route path="/interactcommittee2/:id" element={<InteractCommitteePage />} />
                    <Route path="/rotarians_cooperator" element={<RotariansCooperatorPage />} />
                    <Route path="/rcontacts/:id" element={<RotarianContactsPage />} />
                    <Route path="/fs_iyfr" element={<FsIyfrPage />} />
                    <Route path="/fs_iyfr/:id" element={<FsIyfrPage />} />
                    
                    <Route path="/rcprojects/christmas24" element={<RProjectsShowcase4 />} />
                    <Route path="/rcprojects/christmas24/:id" element={<RProjectsShowcase4 />} />

                //tools test
                    <Route path="/gcalendar" element={<RCalendar />} />
                    <Route path="/gcalendar2" element={<GoogleCalendar />} />
                    <Route path="/qrreader3" element={<QrReadderPage />} />
                    <Route path="/qrgen" element={<RylaCard />} />
                    <Route path="/qrcodegen" element={<QrGeneratorPage />} />
                    <Route path="/goofleforms" element={<CreateGoogleForm />} />
                    <Route path="/fsexplorer" element={<FileExplorer />} /> 
                    <Route path="/map2" element={<ClubsMapMapGL />} />
                    <Route path="/importclubs" element={<ClubsTable />} />
                    {/* <Route path="/overlay-text" element={<OverlayText />} /> */}
                    <Route path="/audiospeechmatic" element={<SpeechmaticAudio />} />

            //temp share
                    <Route path="/tempshare/myhome" element={<MyHomePage_TempShare />} />
                    <Route path="/tempshare/communique" element={<DocumentsPage_TempShare />} />
                    <Route path="/tempshare/finance" element={<FinancePage_TempShare />} />
                    <Route path="/tempshare/events" element={<EventsPage_TempShare />} />
                    
                    <Route path="/policy" element={<PolicyPage />} />
                    <Route path="/termsofservice" element={<TermsofservicePage />} />

            //bc.rotary.org.ua
                    <Route path="/business" element={<BusinessCouncilPage />} />
                    <Route path="/event_BusinessCouncil" element={<BusinessCouncilLanding />} />


            //auth new 08.09.2024
                    <Route path="/register" element={<RegisterForm />} />
                    <Route path="/login" element={<LoginForm />} />
                    <Route path="/register_page" element={<RegisterPage />} />
                    <Route path="/login_page" element={<LoginPage />} />
                    <Route path="/verify-email/:token" element={<VerifyEmailPage />} />
                    <Route path="/home_public" element={<HomePublicPage/>} />
                    <Route path="/reset_pwd" element={<ResetPasswordPage/>} />
                    {/* <Route element={<ProtectedRoute />}> */}
                        <Route path="/home_private" element={<HomePrivatePage />} />
                        <Route path="/myhome2" element={<MyHomePage />} />
                        <Route path="/my" element={<MyRotaryUa />} />
                        <Route path="/my/:idclub" element={<MyRotaryUa />} />
                    {/* </Route> */}
            
            //redirect
                    <Route path="/template/UKR/index.php" element={<BusinessCouncilPage />} />
                    <Route path="/forms/seminargrant24" element={<ExternalRedirect to="https://forms.gle/Q5BjcmT2HGeVV1e78" />} />

                    <Route path="*" element={<Navigate to="/home" replace />} />
                </Routes>
            </Router>
        </LanguageContextProvider>
        </AuthProvider>
    );
}

export default App;
