import { Box, Typography, Container, Link, Paper, Button } from '@mui/material';
import { MdEmail } from 'react-icons/md';
import PhoneIcon from '@mui/icons-material/Phone';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import LandingContentItems from '../../../components/LandingContentItems';
import LcButtonPdf from '../components/LcButtonPdf';
import LcButtonWeb from '../components/LcButtonWeb';
import LcButtonFB from '../../../pages/RC_Cherkasy/components/LcButtonFB';
import LcButtonYoutube from '../../../pages/RC_Cherkasy/components/LcButtonYoutube';
import {handleCopyToClipboard} from './toolsFuncs';
import PhotoCarusel from '../../PhotoCarusel';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import CancelIcon from '@mui/icons-material/Cancel';
//import ProjectPhotoCarusel from './ProjectPhotoCarusel';
import { changeMetafieldLine, getSubfieldValue } from './MetafieldEditLineTools';
import {extractFileNameFromPath} from '../../../modules/ServerFileSystem/FilePathTools';
import {jsonToStringArray} from '../../ProjectCardGUI/toolsfunc/JsonToArrayString';
import { limitString } from '../../../modules/CRUD_system/functions/limitedString';

export const showField = (title, value) => {
    return (
        <>
        {value && <Typography gutterBottom variant="h6" component="div" color= "gray" 
            //style={{ marginBottom: '-0.5rem' }}
        >
            {title}
            <Typography variant="body1" component="span" fontWeight="normal" >{value}</Typography>
        </Typography>}
        </>
    )
}
export const showField_json = (title, value) => {
    return (
        <>
        {value && <Typography gutterBottom variant="h6" component="div" color= "gray"  
            //style={{ marginBottom: '-0.5rem' }}
        >
            {title}
            <Typography variant="body1" component="span" fontWeight="normal" >{jsonToStringArray(value)}</Typography>
        </Typography>}
        </>
    )
}

export const showField_metafield = (title, objitem, metafield, limit) => {
    const value = getSubfieldValue(objitem, metafield);
    return (
        <>
        {value && <Typography gutterBottom variant="h6" component="div" color= "gray" >
            {title} 
            <Typography variant="body1" component="span" fontWeight="normal" >
                {limit ? limitString(value, 6, 355) : value}
            </Typography>
        </Typography>}
        </>
    )
}

export const showField_metafield_thanks = (title, objitem, metafield, limit) => {
    const value = getSubfieldValue(objitem, metafield);
    return (
        <>
        {value && <Typography gutterBottom variant="h6" component="div" color= "gray" >
            <img style={{marginTop:'40px'}} height='60px' src='https://api.rotary.org.ua/uploads/clubsitecreator/showcase2/page_photo/Thanks2.jpg' />
            <Typography variant="body1" component="span" fontWeight="normal" >
                {limit ? limitString(value) : value}
            </Typography>
        </Typography>}
        </>
    )
}
export const showField_metafield_invitation = (title, objitem, metafield, limit) => {
    const value = getSubfieldValue(objitem, metafield);
    return (
        <>
        {value && <Typography gutterBottom variant="h6" component="div" color= "gray" >
            <img style={{marginTop:'20px'}} height='80px' src='https://api.rotary.org.ua/uploads/clubsitecreator/showcase2/page_photo/invitation1.jpeg' />
            <Typography variant="body1" component="span" fontWeight="normal" >
                {limit ? limitString(value) : value}
            </Typography>
        </Typography>}
        </>
    )
}

export const showField_smart = (title, value) => {
    return (
        <>
            <Typography gutterBottom variant="h6" component="div" color="gray">
                {title}
            </Typography>
            <Typography variant="body1" color="text.secondary">
                {value && value.split('\n').map((line, index, array) => {
                    const trimmedLine = line.trim();
                    const prevLine = index > 0 ? array[index - 1].trim() : false;
                    const prevPrevLine = index > 1 ? array[index - 2].trim() : false;

                    const isBr = prevLine === '' && trimmedLine !== '';
                    const isBold = prevLine === '' && prevPrevLine === '' && trimmedLine !== '';

                    const isLink = trimmedLine.includes('http');
                    const isImg = trimmedLine.endsWith('.jpg') || trimmedLine.endsWith('.png');

                    return (
                        <div key={index} style={{ whiteSpace: 'pre-line' }}>
                            {isBr && <br />}
                            {isImg ? (
                                <img src={trimmedLine} alt="image" style={{ width: '30%' }} />
                            ) : isLink ? (
                                <a href={trimmedLine} target="_blank" rel="noopener noreferrer"
                                    style={{ color: 'darkblue', textDecoration: 'none' }}
                                    onMouseEnter={(e) => e.target.style.color = 'blue'}
                                    onMouseLeave={(e) => e.target.style.color = 'darkblue'}
                                >
                                    {trimmedLine}
                                </a>
                            ) : (
                                <Typography
                                    component="span"
                                    style={{ fontWeight: isBold ? 'bold' : 'normal' }}
                                >
                                    {trimmedLine}
                                </Typography>
                            )}
                        </div>
                    );
                })}
            </Typography>
        </>
    );
};



export const showField_title = (value, index, handleRowClick) => {
    return (
        <Typography 
            color='darkblue' 
            gutterBottom 
            variant="h5" 
            component="div" 
            onClick={handleRowClick ? () => handleRowClick(index) : null} 
            fontWeight="bold"
        >
            {value}
        </Typography>
    )
}

export const showFields_contacts = (user) => {
    return (
        <>
            <Typography gutterBottom variant="h7" >
            Primary contact: {user.primarycontact}
            </Typography>

            {user.email && <Tooltip title={'Email'}>
            <IconButton onClick={() => handleCopyToClipboard(user.email)} aria-label="email">
                <MdEmail color="blue" size='1.5em' />
            </IconButton>
            </Tooltip>}

            {user.phone && <Tooltip title={'Phone'}>
            <IconButton onClick={() => handleCopyToClipboard(user.phone)} aria-label="phone">
                <PhoneIcon color="primary" size='1.5em' />
            </IconButton>
            </Tooltip>
            }
            
            {/* {user.youtube && (
                <LcButtonYoutube url={user.youtube} size='34px' />
            )} */}
            {/* {user.fb && (
                <LcButtonFB url={user.fb} size='default' />
            )} */}

            {user.fb && user.fb.split(',').map((webpage, index) => {
                const trimmedUrl = webpage.trim();
                const size = 'default'

                if (trimmedUrl.startsWith('https://www.youtube.com') || trimmedUrl.startsWith('http://www.youtube.com') || trimmedUrl.startsWith('https://youtube.com') || trimmedUrl.startsWith('http://youtube.com')) {
                    return <LcButtonYoutube key={`youtube-${index}`} url={trimmedUrl} size={size} />;
                } else if (trimmedUrl.startsWith('https://www.facebook.com') || trimmedUrl.startsWith('http://www.facebook.com') || trimmedUrl.startsWith('https://www.facebook.com') || trimmedUrl.startsWith('http://www.facebook.com')) {
                    return <LcButtonFB key={`facebook-${index}`} url={trimmedUrl} size={size} color="blue"/>;
                } else {
                    return <LcButtonWeb key={`web-${index}`} url={trimmedUrl} size={size} color="blue"/>;
                }
            })}
        </>
    )
}

export const showField_rclub = (user) => {
    const rclub = user.rclub;
    const rtype = user.rclub;
    return (
        <>
        {user.rclub && <Typography gutterBottom variant="h6" component="div" color= "gray" >
            Club:
            <Typography variant="body1" component="span" fontWeight="normal" >
            {user.rtype && user.rtype==="RAC" 
                ? <>RAC</> 
                : user.rtype==="PRC" 
                ? <>PRC</> 
                : user.rtype==="ReC" 
                ? <>ReC</> 
                : <>RC</>} {user.rclub}
            </Typography>
        </Typography>}
        </>
    )
}

export const listVideoPages_metafield = (objitem, metafield) => {
    const value = getSubfieldValue(objitem, metafield);
    if (value && typeof value === 'string') {
        return (
            <Typography gutterBottom variant="h6" component="div" color= "gray" >
                Video:
                {value.split(',').map((webpage, index) => (
                    <>
                        <Tooltip title={'Video'}>
                        <IconButton onClick={() => window.open(webpage.trim(), '_blank')} aria-label="Website">
                            <VideoLibraryIcon color="red" fontSize={'default'} />
                        </IconButton>
                        </Tooltip>
                        {extractFileNameFromPath(webpage.trim())}
                    </>
                ))}
            </Typography>
        );
    }
    return null;
};

export const listDocsPages = (linksStr, size = 'default') => {
    if (linksStr && typeof linksStr === 'string') {
        return (
            <Typography gutterBottom variant="h6" component="div" color= "gray" >
                Files:
                {linksStr.split(',').map((webpage, index) => (
                    <>
                        <LcButtonPdf url={webpage.trim()} size={size} />
                        {extractFileNameFromPath(webpage.trim())}
                    </>
                ))}
            </Typography>
        );
    }
    return null;
};

export const listWebSocialPages = (linksStr, size = 'default') => {
  if (linksStr && typeof linksStr === 'string') {
      return (
          <Typography gutterBottom variant="h6" component="div" color="gray">
              Webpages: {linksStr.split(',').map((webpage, index) => {
                  const trimmedUrl = webpage.trim();

                  if (trimmedUrl.startsWith('https://www.youtube.com') || trimmedUrl.startsWith('http://www.youtube.com') || trimmedUrl.startsWith('https://youtube.com') || trimmedUrl.startsWith('http://youtube.com')) {
                      return <LcButtonYoutube key={`youtube-${index}`} url={trimmedUrl} size={size} />;
                  } else if (trimmedUrl.startsWith('https://www.facebook.com') || trimmedUrl.startsWith('http://www.facebook.com') || trimmedUrl.startsWith('https://www.facebook.com') || trimmedUrl.startsWith('http://www.facebook.com')) {
                      return <LcButtonFB key={`facebook-${index}`} url={trimmedUrl} size={size} color="blue"/>;
                  } else {
                      return <LcButtonWeb key={`web-${index}`} url={trimmedUrl} size={size} color="blue"/>;
                  }
              })}
          </Typography>
      );
  }
  return null;
};